import React, { useState, useEffect } from 'react';

const Topics = {
    'Biology': 'Biology',
    'Chemistry': 'Chemistry',
    'Physics': 'Physics',
    'Psychology/Sociology': 'Psychology/Sociology',
    'Critical Analysis and Reasoning Skills (CARS)': 'Critical Analysis and Reasoning Skills (CARS)',
};

function App() {
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [currentQuestions, setCurrentQuestions] = useState(null);
    const [answers, setAnswers] = useState({});
    const [score, setScore] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [timer, setTimer] = useState(3000);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [examRecords, setExamRecords] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [carsData, setCarsData] = useState([]);

    useEffect(() => {
        let interval = null;
        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
            alert("Time's up!");
            handleSubmit();
        }
        return () => clearInterval(interval);
    }, [isTimerRunning, timer]);

    useEffect(() => {
        const fetchQuestions = async (file) => {
            try {
                const response = await fetch('assets/' + file);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new Error('Response was not JSON');
                }
                const data = await response.json();

                if (file === "cars.json") {
                    setCarsData(data);
                    setCurrentQuestions(data.length > 0 ? data[0].questions : null);
                } else {
                    setCurrentQuestions(data);
                    setCarsData([]);
                }
                setFetchError(null);
            } catch (error) {
                console.error("Could not fetch questions:", error);
                setFetchError("Could not load questions.");
                setCurrentQuestions(null);
                setCarsData([]);
            }
        };

        if (selectedTopic === 'Biology') {
            fetchQuestions("bio.json");
        } else if (selectedTopic === 'Chemistry') {
            fetchQuestions("chem.json");
        } else if (selectedTopic === 'Physics') {
            fetchQuestions("phys.json");
        } else if (selectedTopic === 'Psychology/Sociology') {
            fetchQuestions("psysoc.json");
        } else if (selectedTopic === 'Critical Analysis and Reasoning Skills (CARS)') {
            fetchQuestions("cars.json");
        } else {
            setCurrentQuestions(null);
            setCarsData([]);
            setFetchError(null);
        }
    }, [selectedTopic]);

    const handleTopicClick = (topic) => {
        setSelectedTopic(topic);
        setAnswers({});
        setScore(null);
        setShowResults(false);
        setTimer(3000);
        setIsTimerRunning(true);
        setStartTime(new Date());
    };

    const handleAnswerChange = (questionIndex, answer) => {
        setAnswers({ ...answers, [questionIndex]: answer });
    };

    const handleSubmit = () => {
        setIsTimerRunning(false);
        const endTime = new Date();
        let correctCount = 0;
        if (currentQuestions) {
            currentQuestions.forEach((question, index) => {
                if (answers[index] === question.correctAnswer) {
                    correctCount++;
                }
            });
        }
        const calculatedScore = (correctCount / (currentQuestions ? currentQuestions.length : 0)) * 100;
        setScore(calculatedScore);
        setShowResults(true);

        const newRecord = {
            section: selectedTopic,
            timestamp: endTime.toLocaleString(),
            timeLeft: formatTime(timer),
            score: calculatedScore.toFixed(2),
        };
        setExamRecords([...examRecords, newRecord]);
    };

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const timerColor = timer > 30 ? 'green' : 'red';
    const timerStyle = {
        alignSelf: 'flex-start',
        fontWeight: 'bold',
        color: timerColor,
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '200px', borderRight: '1px solid #ccc', padding: '10px' }}>
                <input placeholder="Search here" id="searchWidgetTrigger" />
                <gen-search-widget
                    configId="fd710280-9197-40d9-b4e6-34083cabf440"
                    location="us"
                    triggerId="searchWidgetTrigger"
                ></gen-search-widget>

                <h2>MCAT Topics</h2>
                <ul>
                    {Object.keys(Topics).map((topic) => (
                        <li key={topic} style={{ cursor: 'pointer' }} onClick={() => handleTopicClick(topic)}>
                            {topic}
                        </li>
                    ))}
                </ul>
                <div>
                    <h3>Exam Records</h3>
                    <ul>
                        {examRecords.map((record, index) => (
                            <li key={index}>
                                <div>Section: {record.section}</div>
                                <div>Timestamp: {record.timestamp}</div>
                                <div>Time Left: {record.timeLeft}</div>
                                <div>Score: {record.score}%</div>
                                <hr />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <div style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 100,
                    padding: '10px 20px',
                    borderBottom: '1px solid #ccc',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <div style={{
                        textAlign: 'center',
                        fontSize: '0.8em',
                        color: 'red',
                        flex: 1,
                    }}>
                        The contents of this prep-exam are not certified by MCAT. This is for fun and practice only. The answers may not even be correct. Use at your own risk.
                    </div>
                    <div style={timerStyle}>Timer: {formatTime(timer)}</div>
                </div>

                <div style={{ padding: '20px' }}>
                    {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}
                    {selectedTopic ? (
                        <div>
                            <h2>{selectedTopic} Questions</h2>
                            {carsData.length > 0 ? (
                                carsData.map((data, dataIndex) => (
                                    <div key={dataIndex}>
                                        <p>{data.passage}</p>
                                        {data.questions.map((question, index) => (
                                            <div key={index} style={{ marginBottom: '20px' }}>
                                                <p>
                                                    <strong>{index + 1}. {question.question}</strong>
                                                </p>
                                                {question.choices &&
                                                    question.choices.map((choice) => (
                                                        <div key={choice}>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name={`question-${dataIndex}-${index}`}
                                                                    value={choice.charAt(0)}
                                                                    checked={answers[`${dataIndex}-${index}`] === choice.charAt(0)}
                                                                    onChange={() => handleAnswerChange(`${dataIndex}-${index}`, choice.charAt(0))}
                                                                />
                                                                {choice}
                                                                {showResults && (
                                                                    <>
                                                                        {answers[`${dataIndex}-${index}`] === choice.charAt(0) &&
                                                                            answers[`${dataIndex}-${index}`] !== question.correctAnswer && (
                                                                                <span style={{ color: 'red' }}> ❌</span>
                                                                            )}
                                                                        {choice.charAt(0) === question.correctAnswer && (
                                                                            <span style={{ color: 'green' }}> ✅</span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </label>
                                                        </div>
                                                    ))}
                                                {showResults && <p>Reasoning: {question.reasoning}</p>}
                                                {showResults && question.site && (
                                                    <div>
                                                        <a href={question.site} target="_blank" rel="noopener noreferrer">
                                                            Learn More
                                                        </a>
                                                    </div>
                                                )}
                                                {index === data.questions.length - 1 && <hr />}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                currentQuestions && Array.isArray(currentQuestions) && currentQuestions.map((question, index) => (
                                    <div key={index} style={{ marginBottom: '20px' }}>
                                        <p>
                                            <strong>{index + 1}. {question.question}</strong>
                                        </p>
                                        {question.choices &&
                                            question.choices.map((choice) => (
                                                <div key={choice}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`question-${index}`}
                                                            value={choice.charAt(0)}
                                                            checked={answers[index] === choice.charAt(0)}
                                                            onChange={() => handleAnswerChange(index, choice.charAt(0))}
                                                        />
                                                        {choice}
                                                        {showResults && (
                                                            <>
                                                                {answers[index] === choice.charAt(0) &&
                                                                    answers[index] !== question.correctAnswer && (
                                                                        <span style={{ color: 'red' }}> ❌</span>
                                                                    )}
                                                                {choice.charAt(0) === question.correctAnswer && (
                                                                    <span style={{ color: 'green' }}> ✅</span>
                                                                )}
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                            ))}
                                        {showResults && <p>Reasoning: {question.reasoning}</p>}
                                        {showResults && question.site && (
                                            <div>
                                                <a href={question.site} target="_blank" rel="noopener noreferrer">
                                                    Learn More
                                                </a>
                                            </div>
                                        )}
                                        <hr />
                                    </div>
                                ))
                            )}
                            <button onClick={handleSubmit}>Submit Answers</button>
                            {score !== null && <p>Your Score: {score.toFixed(2)}%</p>}
                        </div>
                    ) : (
                        <p>Select a topic to start the quiz.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;